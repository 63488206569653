import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { RootState, OfferActions } from '../store';

import BackButton from './BackButton';
import { BetTypeInfo, OfferDay } from '../store/offer/types';
import MarketTable from './MarketTable';
import { getSelectedCategory, getSelectedDay } from '../store/selectors';

interface Props extends PropsFromRedux {
  isTouchscreen: boolean;
}
const TournamentDetails: React.FC<Props> = (props: Props) => {
  const {
    matches,
    tournament_types,
    tournament_name,
    hideDetails,
    category_name,
    isTouchscreen,
    date,
  } = props;

  const makeTypesToRender = (
    fullPage: boolean,
    allTypes: BetTypeInfo[]
  ): BetTypeInfo[] => {
    let types: BetTypeInfo[] = [];
    let x = 0;
    let i = 0;
    const tcount = allTypes?.length;
    if (fullPage) {
      types = allTypes ?? [];
    } else {
      while (x < 50) {
        const t = allTypes[i];
        x += t.bets.length + 1;
        types.push(t);
        i += 1;
        if (i === tcount) break;
      }
    }
    return types;
  };
  const [typesToRender, setTypesToRender] = React.useState(() =>
    makeTypesToRender(false, tournament_types ?? [])
  );

  const [canScrollRight, setCanScrollRight] = useState(false);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const ul = React.useRef<HTMLDivElement>(null);

  const columns = React.useRef(Array<number>());

  const onDidScroll: EventListener = (e) => {
    if (!ul.current) {
      return;
    }
    const theul = ul.current;

    const ol = theul.scrollLeft;
    const sw = theul.scrollWidth;
    const w = theul.offsetWidth;

    setCanScrollRight(ol + w < sw);
    setCanScrollLeft(ol > 0);
  };

  const tryScroll = (up: boolean) => {
    if (!ul.current) {
      return;
    }
    const theul = ul.current;
    const w = theul.offsetWidth;
    const options: ScrollToOptions = { behavior: 'smooth' };

    if (columns.current.length === 0) {
      options.left = w * (up ? -1 : 1);
      theul.scrollBy(options);
    } else {
      const ol = theul.scrollLeft;
      let offset = ol;
      if (up) {
        const cols = columns.current.slice();
        let v = cols.pop();
        while (v !== undefined) {
          if (v < offset) {
            offset = v;
            v = undefined;
          } else {
            v = cols.pop();
          }
        }
      } else {
        const cols = columns.current.slice();
        let v = cols.shift();
        while (v !== undefined) {
          if (v > offset) {
            offset = v;
            v = undefined;
          } else {
            v = cols.shift();
          }
        }
        // offset = columns.current.reduce((prev, curr) =>
        //   curr > ol && Math.abs(curr - ol) < Math.abs(prev - ol) ? curr : prev
        // );
      }

      options.left = offset;
      theul.scrollTo(options);
    }
  };

  useEffect(() => {
    let tm: NodeJS.Timeout | null = null;

    tm = setTimeout(() => {
      setTypesToRender(makeTypesToRender(true, tournament_types ?? []));
    }, 1);

    return () => {
      if (tm) {
        clearTimeout(tm);
      }
    };
  }, [tournament_types]);

  useLayoutEffect(() => {
    const container = ul.current;
    const bets: NodeListOf<HTMLDivElement> = document.querySelectorAll(
      '.types_container > .bet'
    );
    const cmap: { [key: number]: boolean } = {};
    const newcols: Array<number> = [];
    bets.forEach((b) => {
      const w = b.offsetLeft;
      if (!cmap[w]) {
        cmap[w] = true;
        newcols.push(w);
      }
    });
    columns.current = newcols;

    container?.addEventListener('scroll', onDidScroll);
    //container?.scrollTo({ left: 0 });
    container?.scroll();
    onDidScroll(new MouseEvent('scroll'));
    return () => {
      container?.removeEventListener('scroll', onDidScroll);
    };
  }, [matches, typesToRender, isTouchscreen]);
  let dateString = '';
  if (date) {
    dateString = date.day + (date.date !== '' ? `, ${date.date}` : '');
  }

  return (
    <div id='tdetails'>
      <header>
        <BackButton label='Inapoi' onClose={hideDetails} />
        <div className='breadcrumb'>
          {' '}
          {dateString} &gt;&nbsp;
          {category_name} &gt; {tournament_name}
        </div>
      </header>
      <ul id='events-filter' className=''>
        {matches?.map((m) => (
          <li key={m.code}>
            <code>{m.code}</code>
            <b>{m.name}</b>
          </li>
        ))}
      </ul>

      <div
        ref={ul}
        className='tablet content-wrapper'
        // style={{ left: '320px' }}
      >
        {typesToRender.map((t, index) => (
          <MarketTable key={`${t.id}`} typeId={t.id} betsIds={t.bets} />
        ))}
      </div>
      <div
        id='tdetails-scroll-ct'
        className={`scroll-controller ${
          !canScrollLeft && !canScrollRight ? 'hidden' : ''
        } ${!isTouchscreen ? 'hidden' : ''}`}
      >
        <div
          className={`scroll-up ${canScrollLeft ? 'active' : 'disabled'}`}
          onClick={() => {
            tryScroll(true);
          }}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
          >
            <g id='arrow-more-24px' transform='translate(24 4) rotate(90)'>
              <path
                id='Path_36'
                data-name='Path 36'
                d='M16.8,9.2h0a.967.967,0,0,0-1.4,0L12,12.6,8.6,9.2a.967.967,0,0,0-1.4,0h0a.967.967,0,0,0,0,1.4L12,15.5l4.8-4.9A.967.967,0,0,0,16.8,9.2Z'
                fill='#5d5d5d'
              />
            </g>
          </svg>
        </div>
        <div
          className={`scroll-down ${canScrollRight ? 'active' : 'disabled'}`}
          onClick={() => {
            tryScroll(false);
          }}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
          >
            <g id='arrow-more-24px' transform='translate(0 28 ) rotate(-90)'>
              <path
                id='Path_36'
                data-name='Path 36'
                d='M16.8,9.2h0a.967.967,0,0,0-1.4,0L12,12.6,8.6,9.2a.967.967,0,0,0-1.4,0h0a.967.967,0,0,0,0,1.4L12,15.5l4.8-4.9A.967.967,0,0,0,16.8,9.2Z'
                fill='#5d5d5d'
              />
            </g>
          </svg>
        </div>
      </div>
    </div>
  );
};

interface StateProps {
  tid?: string;
  tournament_name?: string;
  tournament_types?: BetTypeInfo[];
  selectedBetId?: string;
  matches?: { code: string; name: string }[];
  category_name?: string;
  date?: OfferDay;
}

const mapStateToProps = (state: RootState): StateProps => {
  const { offer } = state;
  const currentDate = offer.selectedDate ?? '';
  const tid = offer.selectedTournament;
  const date = getSelectedDay(state);

  if (!tid)
    return {
      tid: undefined,
      tournament_name: undefined,
      tournament_types: undefined,
      selectedBetId: undefined,
      matches: undefined,
      date: undefined,
    };

  const tournament = offer.tournaments[tid];
  const selectedBetId = offer.selectedBet;
  const matchesIds = tournament?.typesByDate[currentDate][0].bets;
  const matches = matchesIds.map((id) => {
    return { code: offer.bets[id].code, name: offer.bets[id].name };
  });

  return {
    tid,
    tournament_name: tournament.name,
    tournament_types: tournament.typesByDate[currentDate],
    selectedBetId,
    matches,
    category_name: getSelectedCategory(state)?.name,
    date,
  };
};

const mapDispatchActions = (dispatch: Dispatch) => {
  return {
    hideDetails: () => {
      dispatch(OfferActions.hideTournamentDetails());
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchActions);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(TournamentDetails);
