import React from 'react';
import { useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';

import { changeCurrentTicket } from '../../store/thunks';
import {
  showPreparedTickets,
  toggleTicketExpanded,
} from '../../store/ticket/ticket-actions';

// import Wheel from '../../img/casa-wheel-new.png';
// import WheelExclusion from '../../img/CPExclusion 3new.png';
import { showWheel } from '../../store/system/actions';
import { TicketMessage } from '../../store/ticket/ticket-types';

const VirtualTicketTabs: React.FC<VirtualTicketTabsReduxProps> = (props) => {
  const {
    currentTicket,
    isShowingPreparedTickets,
    showPreparedTickets,
    // showWheel,
  } = props;
  const dispatch = useDispatch();

  const ticketHandlersStatusClass = [1, 2, 3, 4].map((nr) => {
    return nr === currentTicket && !isShowingPreparedTickets
      ? 'bg-white text-black'
      : ' bg-tint-secondary text-white transition duration-300';
  });

  const changeTicketHandler = (e: React.SyntheticEvent<HTMLElement>) => {
    const ticket = Number(e.currentTarget.dataset.ticket);
    console.log('changeTicket , ', ticket);
    dispatch(changeCurrentTicket(ticket));
  };

  return (
    <div className='flex h-44 lh-44 text-13 semibold'>
      {[1, 2, 3, 4].map((nr) => (
        <div
          key={nr}
          data-ticket={nr}
          onClick={changeTicketHandler}
          className={`${
            ticketHandlersStatusClass[nr - 1]
          } inline-block w-56 text-center mr-1 rounded-t`}
        >
          {nr}
        </div>
      ))}
      <div
        className={`${
          isShowingPreparedTickets
            ? 'bg-white text-black'
            : 'bg-tint-secondary text-white transition duration-300'
        } inline-block px-24 text-center mr-1 rounded-t`}
        onClick={showPreparedTickets}
      >
        Pregătite
      </div>
      {/* <div
        className={`${'bg-casa_yellow text-black transition duration-300'} inline-block uppercase  text-left rounded-t bold relative flex-1 ml-[2px] pl-[8px] `}
        onClick={showWheel}
      >
        <span className='inline-block px-0' style={{ letterSpacing: -0.01 }}>
          Învârte Roata
        </span>

        <div
          className='absolute inline-block overflow-hidden'
          style={{ right: 0, bottom: 0, height: 70, width: 96 }}
        >
          <div
            className='inline-block absolute'
            style={{ right: -47, bottom: -66, width: 133, zIndex: 100 }}
          >
            <div
              className='inline-block relative '
              style={{
                animation: '20s ease-in-out 0s infinite forwards wheel_spin',
              }}
            >
              <img src={Wheel} alt='Wheel' className='inline-block ' />
            </div>
          </div>
          <img
            src={WheelExclusion}
            alt='wheel'
            className='inline-block absolute'
            style={{
              top: 21,
              left: 28,
              zIndex: 24,
              transform: 'scale(1.4, 1.4)',
            }}
          />
        </div>
      </div> */}
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const {
    currentTicket,
    isExpanded,
    isShowingPreparedTickets,
    betslip
  } = state.virtualTicket;

  const miza = betslip?.toPay;
  const totalOdd = betslip?.totalOdds;
  const totalOddString = `${totalOdd?.toFixed((2))}`;
  const ticket_mode = betslip?.betslipType;
  //FIXME: TicketModeInfo
  const ticket_mode_info = "TICKET_MODE_INFO"
  const messages: TicketMessage[] = [];
  const castig = betslip?.potentialWinning

  return {
    miza,
    totalOdd,
    totalOddString,
    ticket_mode,
    ticket_mode_info,
    messages,
    currentTicket,
    castig,
    isShowingPreparedTickets,
    isExpanded,
  };
};

const mapDispatchActions = (dispatch: Dispatch) => {
  return {
    dispatch,
    toggleExpanded: () => dispatch(toggleTicketExpanded()),
    showPreparedTickets: () => dispatch(showPreparedTickets()),
    showWheel: () => dispatch(showWheel()),
  };
};
const virtualTicketTabsConnector = connect(mapStateToProps, mapDispatchActions);

type VirtualTicketTabsReduxProps = ConnectedProps<
  typeof virtualTicketTabsConnector
>;

const ConnectedVirtualTicketTabs =
  virtualTicketTabsConnector(VirtualTicketTabs);
export default ConnectedVirtualTicketTabs;
