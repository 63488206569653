import {
  ActionType,
  CHANGE_CURRENT_PREPARED_TICKET,
  DELETE_PREPARED_VIRTUAL_TICKET,
  DELETE_VIRTUAL_TICKET_MESSAGE,
  RESET_TICKET,
  SEND_TICKETS_TO_APP_LOADING,
  SEND_TICKETS_TO_APP_COMPLETE,
  SEND_TICKETS_TO_APP_ERROR,
  SEND_TICKETS_TO_APP_RESET,
  SHOW_PREPARED_TICKETS,
  START_SESSION,
  TICKET_TOGGLE_EXPANDED,
  UPDATE_TICKETS,
  RESET_ALL_TICKETS,
  VirtualTicketMachine,
  SHOW_TICKET,
} from './ticket-types';

export const initialState: VirtualTicketMachine = {
  
  isVisible: true,
  isExpanded: false,
  currentTicket: 1,
  betslip: undefined,
  betslips: [undefined, undefined, undefined, undefined],

  currentPreparedTicket: undefined,
  isShowingPreparedTickets: false,
  codes: [],
  
  preparedTickets: [],
  
  ticketsToApp: { isLoading: false, error: undefined, session: undefined },
};

export const ticketReducer = (
  state = initialState,
  action: ActionType
): VirtualTicketMachine => {
  switch (action.type) {
    case RESET_ALL_TICKETS: {
      return initialState;
    }

    case SEND_TICKETS_TO_APP_RESET: {
      return {
        ...state,
        ticketsToApp: {
          isLoading: false,
          error: undefined,
          session: undefined,
        },
      };
    }
    case SEND_TICKETS_TO_APP_LOADING: {
      return {
        ...state,
        ticketsToApp: { isLoading: true, error: undefined, session: undefined },
      };
    }

    case SEND_TICKETS_TO_APP_ERROR: {
      return {
        ...state,
        ticketsToApp: {
          isLoading: false,
          error: action.meta.error,
          session: undefined,
        },
      };
    }
    case SEND_TICKETS_TO_APP_COMPLETE: {
      return {
        ...state,
        ticketsToApp: {
          isLoading: false,
          error: undefined,
          session: {
            image: action.meta.imageURL,
            description: action.meta.imageDescription,
          },
        },
      };
    }

    case DELETE_PREPARED_VIRTUAL_TICKET: {
      return {
        ...state,
        isShowingPreparedTickets: state.preparedTickets.length > 1,
        currentPreparedTicket:
          state.preparedTickets.length > 1
            ? Math.min(action.meta.ticket, state.preparedTickets.length - 2)
            : undefined,
        preparedTickets: state.preparedTickets.filter(
          (ticket, index) => index !== action.meta.ticket
        ),
      };
    }

    case DELETE_VIRTUAL_TICKET_MESSAGE: {
      return {
        ...state,
        // ticketServerState: {
        //   ...state.ticketServerState,
        //   messages: state.ticketServerState.messages.filter(
        //     (item, index) => index !== action.meta.index
        //   ),
        // },
      };
    }

    case CHANGE_CURRENT_PREPARED_TICKET: {
      return {
        ...state,
        currentPreparedTicket: action.meta.ticket,
      };
    }
    case SHOW_TICKET: {
      return {
        ...state,
        currentTicket: action.meta.ticket_number,
        betslip: state.betslips[action.meta.ticket_number],
        isShowingPreparedTickets: false,
      };
    }

    case SHOW_PREPARED_TICKETS: {
      return {
        ...state,
        isShowingPreparedTickets: true,
      };
    }
    case TICKET_TOGGLE_EXPANDED: {
      const toggledExpanded = !state.isExpanded;
      return {
        ...state,
        isExpanded: toggledExpanded,
      };
    }
    case START_SESSION: {
      
      return {
        ...state,
      
      };
    }
    case RESET_TICKET: {
      const { ticket_number } = action.meta;
      return {
        ...state,
        [`ticket${ticket_number}`]: undefined,
      };
    }

    case UPDATE_TICKETS: {
      const { data, ticket_number, showPrepared } = action.meta;
      let result = { ...state };
      // const isEmpty =
      if (data !== undefined) {
        let betslipId = data?.betslipId;
        let betslips = state.betslips.slice();
        betslips[ticket_number] = data;
        result.betslips = betslips;

        if (state.currentTicket == ticket_number) {
          result.betslip = data;
        }
        if (data.shortcode) {
          result.preparedTickets = [data, ...state.preparedTickets];
          
        }
      } else {
        result.betslip = undefined;
        result.betslips[ticket_number] = undefined;
      }
      result.isShowingPreparedTickets = showPrepared;
      return result;
    }
    default:
      return state;
  }
};
