import React from 'react';
import { RootState } from '../store';
import { Dispatch } from 'redux';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { toggleTicketExpanded } from '../store/ticket/ticket-actions';
import VirtualTicketEditorHeader from './VirtualTicket/VirtualTicketEditorHeader';
import VirtualTicketEditor from './VirtualTicket/VirtualTicketEditor';
import VirtualTicketTabs from './VirtualTicket/VirtualTicketTabs';
import PreparedVirtualTicketsHeader from './VirtualTicket/PreparedVirtualTicketsHeader';
import PreparedVirtualTicket from './VirtualTicket/PreparedVirtualTicket';
import { reloadApp } from '../hardware';
import { ReactComponent as CasaLogo } from '../img/logo_casa.svg';
import VerticalVirtualTicket from './VirtualTicket/VerticalVirtualTicket';
const ticketIsEmptySelector = (state: RootState) => {
  return state.virtualTicket.betslip  ===undefined
};
const VirtualTickets: React.FC<VirtualTicketReduxProps> = (props) => {
  const {
    dispatch,
    isShowingPreparedTickets,
    mobileAppTickets,
    tickets_count,
  } = props;

  const showTicket = !isShowingPreparedTickets;
  const showPreparedTickets = isShowingPreparedTickets;

  if (mobileAppTickets) {
    const title =
      tickets_count === 1
        ? '1 Cod pregătit'
        : `${tickets_count} Coduri pregătite`;
    return (
      <div
        className='fs-bg-shadow'
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            display: 'inline-block',
            padding: '24px 32px',
            background: '#212225',
            color: '#F8F8F8',
            width: 500,
            textAlign: 'center',
          }}
        >
          <div className='semibold text-20 mb-32'>{title}</div>
          <div
            style={{
              width: 200,
              height: 200,
              display: 'inline-flex',
              flexDirection: 'column',
              borderRadius: 100,
              backgroundColor: '#E5E5E5',
              objectFit: 'contain',
              alignItems: 'center',
              justifyContent: 'center',
              justifyItems: 'center',
            }}
          >
            <img
              style={{
                width: 64,
                height: 64,
                display: 'inline-block',
              }}
              src={mobileAppTickets.image}
              alt={mobileAppTickets.description}
            />

            <div className='inline-block text-dark text-20 mt-8 bold'>
              {mobileAppTickets.description}
            </div>
          </div>

          <div className='mt-16 mb-32 semibold'>TOUCHSCREEN 1</div>
          <div className='text-14 '>
            La casierie indicați numărul terminalului și imaginea de mai sus.
          </div>
          <div className='button mt-24' onClick={reloadApp}>
            Terminat
          </div>
        </div>
      </div>
    );
  }

  const ticketIsEmpty = useSelector(ticketIsEmptySelector);
  const darkDecoration = showTicket && !ticketIsEmpty;

  return (
    <>
      <div className='virtualTicketsWrapper'>
        <div
          className={
            'virtualTicketsBox ' + (darkDecoration ? 'darkerDecoration' : '')
          }
        >
          <div className='ticket-header-wrapper'>
            <div className='flex justify-between items-start pt-4 pb-4'>
              <span className='inline-block text-2xl font-bold tracking-tight'>
                Bilet
              </span>
              <div className='inline-block mr-8 pt-2'>
                <CasaLogo style={{ transform: 'scale(1.2)' }} />
              </div>
            </div>
            {<VirtualTicketTabs />}
          </div>
          <div className='vticket-wrapper'>
            {showTicket && <VerticalVirtualTicket />}
            {showPreparedTickets && (
              <>
                <PreparedVirtualTicketsHeader />
                <PreparedVirtualTicket />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  const { isShowingPreparedTickets, ticketsToApp, preparedTickets } =
    state.virtualTicket;
  return {
    isShowingPreparedTickets,
    mobileAppTickets: ticketsToApp.session,
    tickets_count: preparedTickets.length,
  };
};

const mapDispatchActions = (dispatch: Dispatch) => {
  return {
    dispatch,
    toggleExpanded: () => dispatch(toggleTicketExpanded()),
  };
};
const virtualTicketConnector = connect(mapStateToProps, mapDispatchActions);
type VirtualTicketReduxProps = ConnectedProps<typeof virtualTicketConnector>;
const ConnectedVirtualTicket = virtualTicketConnector(VirtualTickets);
export default ConnectedVirtualTicket;
