export const RESET_TICKET = 'RESET_TICKET';
export const START_SESSION = 'VIRTUAL_TICKET_START_SESSION';
export const UPDATE_TICKETS = 'VIRTUAL_TICKET_UPDATE';
export const TICKET_TOGGLE_EXPANDED = 'TICKET_TOGGLE_EXPANDED';
export const SHOW_PREPARED_TICKETS = 'SHOW_PREPARED_TICKETS';
export const CHANGE_CURRENT_TICKET = 'CHANGE_CURRENT_TICKET';
export const CHANGE_CURRENT_PREPARED_TICKET = 'CHANGE_CURRENT_PREPARED_TICKET';
export const DELETE_VIRTUAL_TICKET_MESSAGE = 'DELETE_VIRTUAL_TICKET_MESSAGE';
export const DELETE_PREPARED_VIRTUAL_TICKET = 'DELETE_PREPARED_VIRTUAL_TICKET';
export const SEND_TICKETS_TO_APP_LOADING = 'SEND_TICKETS_TO_APP_LOADING';
export const SEND_TICKETS_TO_APP_COMPLETE = 'SEND_TICKETS_TO_APP_COMPLETE';
export const SEND_TICKETS_TO_APP_ERROR = 'SEND_TICKETS_TO_APP_ERROR';
export const SEND_TICKETS_TO_APP_RESET = 'SEND_TICKETS_TO_APP_RESET';
export const RESET_ALL_TICKETS = 'RESET_ALL_TICKETS';
export const SHOW_TICKET = 'SHOW_TICKET';
interface SendTicketsToAppLoading {
  type: typeof SEND_TICKETS_TO_APP_LOADING;
}
interface SendTicketsToAppError {
  type: typeof SEND_TICKETS_TO_APP_ERROR;
  meta: {
    error: string;
  };
}

interface SendTicketsToAppComplete {
  type: typeof SEND_TICKETS_TO_APP_COMPLETE;
  meta: {
    imageURL: string;
    imageDescription: string;
  };
}

interface SendTicketsToAppReset {
  type: typeof SEND_TICKETS_TO_APP_RESET;
}

interface DeletePreparedVirtualTicket {
  type: typeof DELETE_PREPARED_VIRTUAL_TICKET;
  meta: {
    ticket: number;
  };
}

interface DeleteVirtualTicketMessage {
  type: typeof DELETE_VIRTUAL_TICKET_MESSAGE;
  meta: {
    index: number;
  };
}
interface ChangeCurrentPreparedTicket {
  type: typeof CHANGE_CURRENT_PREPARED_TICKET;
  meta: {
    ticket?: number;
  };
}

interface ChangeCurrentTicket {
  type: typeof CHANGE_CURRENT_TICKET;
  meta: {
    ticket: number;
  };
}

interface ShowPreparedTickets {
  type: typeof SHOW_PREPARED_TICKETS;
}

interface ToggleTicketExpanded {
  type: typeof TICKET_TOGGLE_EXPANDED;
}

interface ResetTicket {
  type: typeof RESET_TICKET;
  meta: {
    ticket_number: number;
  };
}

interface VirtualTicketStartSessionAction {
  type: typeof START_SESSION;
  meta: {
    ticket: FEG_Betslip;
  };
}

interface VirtualTicketUpdateAction {
  type: typeof UPDATE_TICKETS;
  meta: {
    data?: FEG_Betslip;
    ticket_number: number;
    showPrepared: boolean;
  };
}
interface ShowTicketAction {
  type: typeof SHOW_TICKET;
  meta: {
    ticket_number: number;
  };
}

interface ResetAllTickets {
  type: typeof RESET_ALL_TICKETS;
}

export type ActionType =
  | ResetTicket
  | VirtualTicketStartSessionAction
  | VirtualTicketUpdateAction
  | ToggleTicketExpanded
  | ShowPreparedTickets
  | ChangeCurrentTicket
  | ChangeCurrentPreparedTicket
  | DeleteVirtualTicketMessage
  | DeletePreparedVirtualTicket
  | SendTicketsToAppLoading
  | SendTicketsToAppError
  | SendTicketsToAppComplete
  | SendTicketsToAppReset
  | ResetAllTickets
  | ShowTicketAction;

export type VirtualTicketMachine = {
  
  isVisible: boolean;
  isExpanded: boolean;
  currentTicket: number;
  betslip: FEG_Betslip | undefined;
  betslips: (FEG_Betslip | undefined)[];
  currentPreparedTicket: number | undefined;
  
  codes: string[];
  preparedTickets: FEG_Betslip[];
  isShowingPreparedTickets: boolean;
  ticketsToApp: {
    isLoading: boolean;
    error?: string;
    session?: { image: string; description: string };
  };
};

export interface VirtualTicketState {
  activeId: number;
  data_ids1: number[];
  data_ids2: number[];
  data_ids3: number[];
  data_ids4: number[];
  totalOdd: number;
  totalOddString: string;
  miza: number;
  castig: number;
  legal: string;
  ticket_mode: string;
  ticket_mode_info: string;
  codes: string[];
  oids: string[];
  bets: { [key: string]: TicketBet };
  messages: TicketMessage[];
  groups: TicketGroup[];
  shortCode: string | undefined;
  prep_time: string | undefined;
  date_created: Date | undefined;
  ticketId: string | undefined;
  ticket_types: TicketType[];
  systemGroupBets: SystemGroupBet[];
  combiGroupBets: CombiGroupBet[];
}
export interface CombiGroupBet {
  index: number;
  info: string;
  group: string;
  combination: string;
}
export interface SystemGroupBet {
  checked: boolean;
  value: string;
  index: number;
  info: string;
}

export interface TicketType {
  id: string;
  label: string;
  selected: boolean;
}

export interface TicketMessage {
  text: string;
  class: string;
}

export interface TicketGroup {
  name: string;
  selected: boolean;
  odd: string | undefined;
  bets: string[];
}

export interface OddInfo {
  id: string;
  info: string;
  value: string;
  name: string;
  selected: boolean;
}

export interface TicketBet {
  code: string;
  title: string;
  name: string;
  id: string;
  index: string;
  matchId: string;
  sport: string;
  sportId: string;
  competition: string;
  competitionId: string;
  description: string;
  odd: { name: string; value: string };
  otherOdds: OddInfo[];
  groups: TicketGroup[];
  class: string;
  fixed: boolean;
  icon: string;
}

export interface FEG_Betslip {
  availableBetslipTypes: string[];
  betslipId: string;
  betslipToken?: string;
  betslipType: 'NONE' | 'SOLO' | 'LEG_COMBI' | 'AKO' | 'GROUP_COMBI';
  toPay: number;
  stake: FEG_BetslipStake;
  totalOdds: number;
  potentialPayoutDetails: {
    winGross: number;
    winNet: number;
    winAlt: number;
    winGrossWithoutBonus: number;
    tax: { amoung: number; taxRate: number };
  };
  // prepared ticket
  betslipNumber?: string;
  shortcode?: string;
  securityCode?: string;
  potentialWinning?: number;
  potentialWinningGrossWithoutBonus?: number;
  placementDetails?: {
    time: string;
    paid?: number;
    loyaltyPoints?: number;
    ipAddress?: string;
    handlingFee?: {
      amount: number;
      feeRate: number;
      feeType: string;
    };
  };
  legs: FEG_BetslipLeg[];
  groups: { [id: string]: FEG_BetslipGroup };
}
export interface FEG_BetslipStake {
  stakeTotal: number;
  loyaltyPointsStakeTotal: number | undefined;
  combi?: {
    totalStakeLocked: boolean;
    systems: FEG_BetslipStakeCombiSystem[];
  };
  combiStakeEntries?: FEG_BetslipCombiStakeEntry[];
  // combiStakeEntries: number[] | undefined
}
export interface FEG_BetslipCombiStakeEntry {
  lineLength: number;
  numOfLines: number;
  stakePerCombination: number;
  grossStakePerCombination: number;
  stakePerLine: number;
  grossStakePerLine: number;
}
export interface FEG_BetslipStakeCombiSystem {
  index: number;
  enabled: boolean;
  activeCombinationsCount: number;
  systemStake: FEG_BetslipCombinationStake;
  combinationStake: FEG_BetslipCombinationStake;
}
export interface FEG_BetslipCombinationStake {
  locked: boolean;
  stake: number;
  netStake: number;
  loyaltyPointsStake: number;
}

export interface FEG_BetslipGroup {
  banker: boolean; // fixed in system
  groupId: number; // 65
  name: string; // A
  oddsPlaced: number;
  legs: FEG_BetslipLeg[];
}

export interface FEG_BetslipLeg {
  legId: number;
  groupName?: string;
  selection: {
    category: FEG_BetslipCategory;
    fixture: FEG_BetslipFixture;
    market: FEG_BetslipMarket;
    selection: FEG_BetslipSelection;
    sport: FEG_BetslipSport;
    tournament: FEG_BetslipTournament;
  };
  oddsOrigin: number;
}

export interface FEG_BetslipTournament {
  name: string;
  torunamentId: string;
}
export interface FEG_BetslipSport {
  name: string;
  sportId: string;
  category: string; // ""
}

export interface FEG_BetslipSelection {
  name: string;
  odds: number;
  outcomeId: string;
  selectionId: string;
  state: string;
}
export interface FEG_BetslipFixture {
  fixtureId: string;
  fixtureType: string;
  name: string;
  startTime: string;
  mainMarketId: string;
}
export interface FEG_BetslipCategory {
  categoryId: string;
  name: string;
}

export interface FEG_BetslipMarket {
  category: string; // enum: MATCH |
  infoNumber: string;
  marketId: string;
  marketTypeId: string;
  parentId: string;
  name: string;
  state: string; // enum: ACTIVE |
}

export const BETSLIP_TYPE_LABELS: { [id: string]: string } = {
  SOLO: 'SIMPLU',
  GROUP_COMBI: 'MAXICOMBI',
  AKO: 'MULTIPLU',
  LEG_COMBI: 'SISTEM',
  NONE: '-',
};

let ticket = {
  availableBetslipTypes: [],
  availableGroups: [],
  betslipId: '929690337596276787',
  betslipType: 'NONE',
  bonuses: [],
  bonusesSummary: { bonusAmount: 0, bonusPercentage: 0 },
  country: 'RO',
  currency: 'RON',
  channel: 'WEB_ONLINE',
  config: {
    MAX_FKP_POINTS_DAY: { value: '500' },
    PAYMENT_METHODS_ALLOWED: {
      value: 'ACCOUNT',
    },
  },
  groups: {},
  legs: [],
  messages: [],
  handlingFee: {
    amount: 0,
    feeRate: 0,
    feeType: 'PAYMENT_BASED_MINUS',
  },
  payinTax: {
    amount: 0,
    taxRate: 0,
    coefficient: 1,
  },
  loyaltyPointsGained: 0,
  loyaltyPointsTotal: 0,
  toPay: 2.0,
  potentialPayoutDetails: {
    winGross: 0,
    winNet: 0,
    winAlt: 0,
    winGrossWithoutBonus: 0,
    tax: {
      amount: 0,
      taxRate: 0,
    },
  },
  potentialMinimalWinning: {
    potentialMinWin: 0,
    potentialMinWinGross: 0,
    potentialMinWinTaxAmount: 0,
  },
  paymentType: 'ACCOUNT',
  productType: 'SINGLE',
  roster: {
    combinations: [],
    errors: [],
  },
  stake: { stakeTotal: 2.0, loyaltyPointsStakeTotal: 100 },
  totalOdds: 0,
  totalOddsWithoutBonus: 0,
  leaderboard: {
    enabled: false,
    eligible: false,
  },
  combinability: { allowed: {} },
};