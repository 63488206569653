export const reloadApp = (): void => {
  try {
    window.location.reload();
  } catch (ex) {
    console.error('reloadApp err ', ex);
  }
};

export const getBetslipsAPI = (): string => {
  return process.env.REACT_APP_BETSLIPS_API as string;
}