import React, { useEffect, useState } from 'react';

import { RootState } from '../../store';
import {
  changeCurrentPreparedTicket,
  deletePreparedVirtualTicket,
} from '../../store/ticket/ticket-actions';
import { Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import VirtualTicketBetRow from './VirtualTicketBetRow';
import FullScreenMessageBox from '../FullScreenMessageBox';

import { ReactComponent as XIcon } from '../../img/close-24px.svg';
import { BETSLIP_TYPE_LABELS, TicketGroup } from '../../store/ticket/ticket-types';
import { Bet } from '../../store/offer/types';
import { formatDate } from '../../utils';

const PreparedVirtualTicket: React.FC<ReduxProps> = (props) => {
  const {
    currentPreparedTicket,
    tickets,
    hasPrinterForTickets,
    hasAppForTickets,
    printerHostname,
    printingAPI,
    dispatch,
  } = props;
  const [showDeleteTicketConfirmation, setShowDeleteTicketConfirmation] =
    useState<string | undefined>(undefined);
  useEffect(() => {
    console.log(tickets);
  }, [tickets]);

  if (tickets.length === 0) {
    return null;
  } else {
    let betslip = tickets[currentPreparedTicket ?? 0];
    let betslip_date = betslip.placementDetails?.time
      ? new Date(betslip.placementDetails.time)
      : new Date();
    let betslip_date_string = formatDate(betslip_date);

    const handleChangeCurrentPreparedTicket = (
      e: React.SyntheticEvent<HTMLDivElement>
    ) => {
      const ticketIndex = Number(e.currentTarget.dataset.ticket);
      console.log('changing prepared ticket');
      dispatch(changeCurrentPreparedTicket(ticketIndex));
    };

    const handleDeleteTicket = (e: React.SyntheticEvent<HTMLDivElement>) => {
      const ticketCode = e.currentTarget.dataset.ticket;
      setShowDeleteTicketConfirmation(ticketCode);
    };

    const handlePrintTicket = async (
      e: React.SyntheticEvent<HTMLDivElement>
    ) => {
      const ticketCode = e.currentTarget.dataset.ticket;
      const api = printingAPI;
      if (!api) {
        return;
      }
      console.log(`to print code: ${ticketCode}`);

      const ticketId = tickets.findIndex((t) => t.shortcode === ticketCode);
      if (ticketId < 0) {
        console.error('ticket not found');
        console.debug(tickets);
        return;
      }
      const ticket = tickets[ticketId];
      console.log(`found ticket ${ticket}`);
       //FIXME: when printing all bets and groups on ticket
       const groups: TicketGroup[] = [];
       const bets: Bet[] = [];
       let date = ticket.placementDetails?.time
         ? new Date(ticket.placementDetails.time)
         : new Date();
       let date_string = formatDate(date);
      const data = {
        hostname: printerHostname,
        barcode: ticket.betslipNumber,
        code: ticket.shortcode,
        date: date_string,
        tip: BETSLIP_TYPE_LABELS[ticket.betslipType],
        miza: `${ticket.placementDetails?.paid ?? 0}`,
        castig: `${ticket.potentialWinning}`,
        odds: `${ticket.totalOdds}`,
        mode: BETSLIP_TYPE_LABELS[ticket.betslipType],
        groups,
        bets,
      };
      try {
        const res = await fetch(api, {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data),
        });
      } catch (err) {
        console.log('Error printing ticket');
      }
    };

    return (
      <>
        {showDeleteTicketConfirmation && (
          <FullScreenMessageBox
            message={`Ești sigur că vrei să ștergi biletul ${
              tickets[Number(showDeleteTicketConfirmation)].shortcode
            }?`}
            cancelLabel='Închide'
            continueLabel='Da'
            onCancel={() => setShowDeleteTicketConfirmation(undefined)}
            onContinue={() => {
              dispatch(
                deletePreparedVirtualTicket(
                  Number(showDeleteTicketConfirmation)
                )
              );
              setShowDeleteTicketConfirmation(undefined);
            }}
          />
        )}
        {currentPreparedTicket !== undefined && (
          <div className='fixed right-[602px] top-[157px] min-h-[100px] max-h-[500px]'>
            <div className='relative rounded-lg drop-shadow-2xl bg-black p-6 pr-[54px]'>
              <div
                className={
                  'absolute right-[-8px] top-2 scroll-button mb-2 color-black '
                }
                onClick={() => dispatch(changeCurrentPreparedTicket(undefined))}
              >
                <XIcon className='fill-current' />
              </div>
              <div className='block font-[22px] font-bold tracking-tight text-casa_yellow'>
                {betslip.shortcode ?? ""}
              </div>

              <div className='ticket-body flex-shrink-0' style={{ width: 527 }}>
              {betslip.betslipType !== 'GROUP_COMBI' && (
                  <>
                    {betslip.legs.map((leg, index) => (
                      <VirtualTicketBetRow
                      key={leg.legId}
                      leg={leg}
                      groups={betslip?.groups ?? {}}
                      editable={false}
                      mode={BETSLIP_TYPE_LABELS[betslip.betslipType]}
                      roundedTop={index !== 0}
                      />
                    ))}
                  </>
                )}
                 {betslip.betslipType === 'GROUP_COMBI' && (
                  <>
                     {Object.values(betslip.groups).map((group) => (
                      <div key={group.name} className='mb-24'>
                        <div className='h-36 lh-36 text-11 text-white semibold flex mb-1'>
                          <div className='inline-flex space-between flex-grow bg-gray-gradient rounded-t mr-1 px-12 box-border'>
                            <span>
                              GRUP{' '}
                              <span className='text-yellow'>{group.name}</span>
                            </span>
                            <span>
                              {group.legs.length}{' '}
                              {group.legs.length !== 1 ? 'Pariuri' : 'Pariu'}
                            </span>
                          </div>
                          <div
                            className='inline-flex space-between bg-gray-gradient rounded-t px-12 box-border'
                            style={{ width: 111 }}
                          >
                            <span>Cota</span>
                            <span className='text-yellow'>{group.oddsPlaced}</span>
                          </div>
                        </div>

                        {group.legs.map((leg, index) => (
                    <VirtualTicketBetRow
                      key={leg.legId}
                      leg={leg}
                      groups={betslip?.groups ?? {}}
                      editable={false}
                      roundedTop={index !== 0}
                    />
                        ))}
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        )}

        <div className='inline-block flex-1 overflow-y-scroll  box-content '>
          {tickets.map((ticket, index) => (
              
            <div key={ticket.shortcode} className='w-full mb-24 flex flex-row '>
              <div
                className={
                  'relative inline-flex justify-between flex-col rounded flex-1 pl-[20px] pr-[24px] py-[10px]  text-black h-[76px] ' +
                  (index === currentPreparedTicket
                    ? 'drop-shadow-xl bg-casa_yellow transition duration-300'
                    : 'bg-white scale-95 transition duration-300')
                }
              >
                <div className='absolute inline-block left-[-10px] top-[28px] h-[20px] w-[20px] rounded-[10px] bg-casa'></div>
                <div className='absolute inline-block right-[-10px] top-[28px] h-[20px] w-[20px] rounded-[10px] bg-casa'></div>
                <div className='flex text-11 semibold justify-between items-center '>
                  <span className='inline-block text-left'>
                    {betslip_date_string}
                  </span>
                  <span className='inline-block'>
                  {BETSLIP_TYPE_LABELS[ticket.betslipType]}
                  </span>
                </div>
                <div
                  className='flex flex-row'
                  data-ticket={index}
                  onClick={handleChangeCurrentPreparedTicket}
                >
                  <div className='inline-block  flex-grow self-end text-[22px] bold tracking-tight'>
                    {ticket.shortcode}
                  </div>

                  <div className='inline-grid grid-cols-2  gap-4 text-center text-[10px]'>
                    <span>Miza (lei):</span>
                    <span>Castig posibil (lei):</span>
                    <b className='text-[12px]'>{ticket.placementDetails?.paid}</b>
                    <b className='text-[12px]'>{ticket.potentialWinning}</b>
                  </div>
                </div>
              </div>

              <div
                className={`ml-[22px] inline-flex flex-col justify-between box-content`}
              >
                {hasPrinterForTickets === true && (
                  <div
                    data-ticket={ticket.shortcode}
                    className='inline-flex h-[34px]  px-32 items-center justify-center bg-white text-black text-[13px] bold rounded text-center drop-shadow'
                    onClick={handlePrintTicket}
                  >
                    Printează
                  </div>
                )}
                <div
                  data-ticket={index}
                  className='inline-flex h-[34px] items-center justify-center border-white border-solid border text-white px-32 text-[13px] bold rounded text-center'
                  onClick={handleDeleteTicket}
                >
                  Șterge
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  }
};
const mapStateToProps = (state: RootState) => {
  const { preparedTickets, currentPreparedTicket } = state.virtualTicket;
  const { printingMode, printerHostname, printingAPI } = state.settings!.config;
  return {
    currentPreparedTicket,
    tickets: preparedTickets,
    hasPrinterForTickets: printingMode === 'printer',
    hasAppForTickets: printingMode === 'app',
    printerHostname,
    printingAPI,
  };
};

const mapDispatchActions = (dispatch: Dispatch) => {
  return {
    dispatch,
  };
};
const connector = connect(mapStateToProps, mapDispatchActions);

type ReduxProps = ConnectedProps<typeof connector>;

const ConnectedPreparedVirtualTicket = connector(PreparedVirtualTicket);
export default ConnectedPreparedVirtualTicket;
